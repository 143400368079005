"use client";
import Skeleton from "react-loading-skeleton";
import React, { Suspense, useCallback } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";

/* Components */
import { SubHeaderItems } from "./items";
import { subHeaderWrapper } from "./styled.css";
import { UnstyledButton } from "@components";

/* Hooks */
import { useDragScroll, useWindowSize } from "@src/hooks";

type Props = {
  children?: React.ReactNode;
};

export const SubHeader: React.FC<Props> = (props) => {
  const {} = props;

  /* Hooks */
  const { sizes, bp } = useWindowSize();
  const [overflowContainerRef, handlers] = useDragScroll<HTMLDivElement>({
    sensitivity: 0.5,
    momentum: 0.85,
  });

  /* Handlers */
  const handleScroll = useCallback((isPositive = true) => {
    if (!overflowContainerRef.current) return;
    const SCROLL_CONSTANT = 120;

    const position = overflowContainerRef.current.scrollLeft;
    const scrollWidth = overflowContainerRef.current.scrollWidth;
    let dx = isPositive
      ? position + SCROLL_CONSTANT
      : position - SCROLL_CONSTANT;

    if (isPositive && position + SCROLL_CONSTANT > scrollWidth)
      dx = scrollWidth;
    else if (!isPositive && position - SCROLL_CONSTANT < 0) dx = 0;

    overflowContainerRef.current.scrollTo({
      left: dx,
      behavior: "smooth",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={subHeaderWrapper}>
      <div className="relative">
        {sizes.width > bp.sm && (
          <UnstyledButton
            onClick={() => handleScroll(false)}
            onMouseOver={() => handleScroll(false)}
            aria-label="Scroll left"
            className="absolute text-primary-text top-1/2 -translate-y-1/2"
          >
            <ArrowLeftIcon
              fill="currentColor"
              fontSize={32}
              width={32}
              height={32}
            />
          </UnstyledButton>
        )}
        <Suspense fallback={<Skeleton count={10} width={24} />}>
          <SubHeaderItems handlers={handlers} ref={overflowContainerRef} />
        </Suspense>

        {sizes.width > bp.sm && (
          <UnstyledButton
            onClick={() => handleScroll(true)}
            onMouseOver={() => handleScroll(true)}
            aria-label="Scroll right"
            className="absolute text-primary-text top-1/2 right-0 -translate-y-1/2"
          >
            <ArrowRightIcon
              fill="currentColor"
              fontSize={32}
              width={32}
              height={32}
            />
          </UnstyledButton>
        )}
      </div>
    </div>
  );
};
