"use client";

import React, { useMemo } from "react";

/* Components */
import { ContactForm } from "./form";
import { Logo } from "@src/assets/logo";
import { Heading, Text } from "@components";

/* Utils */
import { FormatDate } from "@utils/dates-fns";

/* Data */
import * as footerData from "./data.json";

type Props = {
  children?: React.ReactNode;
};

const HomeFooter: React.FC<Props> = (props) => {
  const {} = props;

  /* Memos */
  const currentYear = useMemo(() => FormatDate(new Date(), "yyyy"), []);

  return (
    <div className="mt-24 pt-6 bg-neutral-gray">
      <div className="container flex flex-col gap-6 w-full">
        <div className="grid grid-rows-[1fr_2fr] grid-cols-1 lg:grid-rows-1 lg:grid-cols-[1fr_1fr] justify-between items-center w-full">
          <div className="w-full justify-items-center lg:justify-items-start self-start flex-col flex">
            <FooterAbout />
          </div>
          <div className="flex flex-col gap-6 w-full p-4">
            <Heading size="2xl">Contact Form</Heading>
            <div className="flex w-full">
              <ContactForm />
            </div>
          </div>
        </div>
        <div className="grid grid-rows-[1fr_2fr] text-center lg:text-left lg:grid-rows-1 lg:grid-cols-4"></div>
        <Text>{`Copyright © ${currentYear}. Powered by Moss Tech.`}</Text>
      </div>
    </div>
  );
};

const FooterAbout = () => (
  <>
    <Logo fontSize="6rem" />
    <div className="flex flex-col mt-8 gap-2">
      <Heading size="lg">Coporate & Communication Address:</Heading>

      <Text className="pl-4">{"Email: " + footerData.email}</Text>
      <Text className="pl-4">{"Office Location: " + footerData.address}</Text>
      <div className="flex flex-col gap-2 mt-4">
        <Heading size="lg">Phone Numbers:</Heading>
        {footerData.phone.map((phone, i) => (
          <Text key={phone + i} className="pl-4">
            {phone}
          </Text>
        ))}
      </div>
    </div>
  </>
);

export default HomeFooter;
